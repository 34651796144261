import * as models from '../../store/models/store.model';
import * as fromUI from '../../store/actions/';

const estadoInicial: models.ConfigurationState = {
  installationCode: null,
  actualUser: null,
  initialData: null,
};

export function configurationReducer(
  state = estadoInicial,
  action: fromUI.configurationActions
): models.ConfigurationState {
  switch (action.type) {
    case fromUI.SETINSTALLATIONCODE:
      if (state.installationCode != null && state.installationCode.length > 0) {
        let InstallationCodes = [...state.installationCode];

        if (
          state.installationCode.filter(
            (instaCode: any) =>
              instaCode.codigoInstalacion ==
              action.installationCodes[0].codigoInstalacion
          ).length == 0
        ) {
          InstallationCodes.push(action.installationCodes[0]);
        }
        return {
          ...state,
          installationCode: InstallationCodes,
        };
      } else {
        return {
          ...state,
          installationCode: action.installationCodes,
        };
      }

    case fromUI.SETACTIVEUSER:
      return {
        ...state,
        actualUser: action.actualUser,
      };

    case fromUI.REMOVEINSTALLATIONCODE:
      if (state.installationCode) {
        let installationCodes = [...state.installationCode];
        installationCodes.splice(
          installationCodes.findIndex(
            (x) => x.codigoInstalacion == action.installationCode
          ),
          1
        );
        if (installationCodes.length == 0) {
          return {
            ...state,
            installationCode: null,
          };
        } else {
          return {
            ...state,
            installationCode: installationCodes,
          };
        }
      } else {
        return state;
      }

    case fromUI.SETNEWTOKEN:
      if (state.actualUser) {
        return {
          ...state,
          actualUser: {
            ...state.actualUser,
            token: action.newToken,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case fromUI.SETCONFIGURATIONDATA:
      return {
        ...state,
        installationCode: action.configurationData.installationCode,
        actualUser: action.configurationData.actualUser,
      };

    case fromUI.REMOVEUSERDATA:
      return {
        ...state,
        actualUser: null
      };

    case fromUI.REMOVECONFIGURATIONDATA:
      return {
        ...state,
        installationCode: null,
        actualUser: null,
      };

    case fromUI.SETINITIALDATA:
      return {
        ...state,
        initialData: action.initialData,
      };

    case fromUI.UNSETINITIALDATA:
      return {
        ...state,
        initialData: null,
      };

    case fromUI.UNSETCONFIGURATION:
      return {
        ...state,
        actualUser: null,
        initialData: null,
      };

    case fromUI.SETTRANSACTIONTYPESDATA:

      return {
        ...state,
        initialData: {
          financiers: state.initialData?.financiers || null,
          providers: state.initialData?.providers || null,
          selectedProviders: state.initialData?.selectedProviders || null,
          transactionTypes: action.transactionTypes,
          prescriptionTypes: state.initialData?.prescriptionTypes || null,
        },
      };

    case fromUI.SETPRESCRIPTIONDATA:

      return {
        ...state,
        initialData: {
          financiers: state.initialData?.financiers || null,
          providers: state.initialData?.providers || null,
          selectedProviders: state.initialData?.selectedProviders || null,
          transactionTypes: state.initialData?.transactionTypes || null,
          prescriptionTypes: action.prescripcionTypes
        },
      };

    default:
      return state;
  }
}
