import { AttentionPlace } from "@models/attention-place.model";
import { InstallationState } from "../models/store.model";
import * as fromInstallation from "../../store/actions"


const estadoInicialInstalacion: InstallationState = {
  userInstallation: null,
  attentionPlaceSelected: null,
  attentionPlaces: Array<AttentionPlace>()
};

export function instalacionReducer(
  state = estadoInicialInstalacion,
  actions: fromInstallation.InstallationActions
): InstallationState {
  switch (actions.type) {

    case fromInstallation.UPLOADINSTALLATIONUSERS:
        return {
          ...state,
          userInstallation: { ...actions.installationUsers },
          attentionPlaceSelected: null,
        };

    case fromInstallation.SELECTATTENTIONPLACE:
      return {
        ...state,
        attentionPlaceSelected: actions.attentionPlace,
      };

    case fromInstallation.DELETEINSTALLATIONDATA:
      return {
        userInstallation: null,
        attentionPlaceSelected: null,
        attentionPlaces: Array<AttentionPlace>()
      };

    default:
      return state;
  }
}
