import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as models from '../store/models/store.model';
import * as reducers from '../store/reducers';

export interface AppState {
    installation: models.InstallationState;
    configuration: models.ConfigurationState;
}

export const appReducers: ActionReducerMap<AppState, any> = {
    installation: reducers.instalacionReducer,
    configuration: reducers.configurationReducer,
  };


export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return function(state: AppState, action: Action): AppState {
        if (action.type === 'RESET_APP') {
          state = undefined;
      }
      return reducer(state, action);
    };
  }

  export const metaReducers: MetaReducer<AppState>[] = [clearState];
