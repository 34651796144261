import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';

import { PersistenceService } from '@services/persistence.service';
import { SetConfigurationData } from './store/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {

  private persistenceService = inject(PersistenceService);

  constructor(
    private store: Store<AppState>
  ) {
    this.store.dispatch(
      new SetConfigurationData(this.persistenceService.recuperarDatos())
    );
  }
}
