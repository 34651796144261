// import { inject } from '@angular/core';
// import { CanActivateFn, Router } from '@angular/router';
// import { of } from 'rxjs';

// export const NoAuthGuard: CanActivateFn = (route, state) => {
//     const router: Router = inject(Router);

//     const installationCodes = localStorage.getItem('installationCodes');
//     const parsedInstallationCodes = installationCodes ? JSON.parse(installationCodes) : [];

//     if (state.url === '/installation') {
//         return of(true);
//     }
//     if (state.url === '/sign-up') {
//         return of(true);
//     }

//     // Si no hay códigos de instalación, redirigir a la instalación
//     if (parsedInstallationCodes.length === 0) {
//         if (state.url !== '/installation') {
//             return of(router.parseUrl('installation'));
//         }
//     } else {
//         // Si hay códigos de instalación, redirigir al inicio de sesión
//         if (state.url !== '/sign-in') {
//             return of(router.parseUrl('sign-in'));
//         }
//     }

// };

import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state
) => {
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService)
        .check()
        .pipe(
            switchMap((authenticated) => {
                // If the user is authenticated...
                if (authenticated) {
                    return of(router.parseUrl(''));
                }

                // Allow the access
                return of(true);
            })
        );
};
